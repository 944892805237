import React from "react"

import Footer from "../components/Footer"
import Iubenda from "../components/Iubenda"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Navbar from "../components/Navbar"
import { PageProps } from "../helpers/props"

const CookiePolicyPage: React.FunctionComponent<PageProps> = ({ location }) => {
  const title = "Cookie Policy"

  return (
    <Layout>
      <Meta pathname={location.pathname} title={title} />
      <Navbar />
      <section className="section">
        <div className="container">
          <h1 className="title is-1 has-text-centered">{title}</h1>
          <Iubenda
            href="https://www.iubenda.com/privacy-policy/75568957/cookie-policy"
            title={title}
          >
            Please click here if the policy does not load.
          </Iubenda>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default CookiePolicyPage
