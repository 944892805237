import React, { useEffect } from "react"

interface IubendaProps {
  children?: any
  href: string
  title: string
}

const Iubenda: React.FunctionComponent<IubendaProps> = ({ children, href, title }) => {
  useEffect(() => {
    const iubendaScriptId = "iubenda-loader"
    let script = document.getElementById(iubendaScriptId)
    if (script) {
      script.remove ? script.remove() : (script.outerHTML = "")
    }
    script = document.createElement("script")
    script.id = iubendaScriptId
    script.src = "https://cdn.iubenda.com/iubenda.js"
    document.body.appendChild(script)
  })

  return (
    <a
      className="iubenda-white no-brand iubenda-embed iub-body-embed has-text-centered is-block"
      href={href}
      title={title}
    >
      {children}
    </a>
  )
}

export default Iubenda
